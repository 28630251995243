import React from "react"
import styled from "styled-components"
import SectionTitle from "../titles/sectiontitle"

interface Props {
  title: string
  description: string
}

const SmallDetailsCard = (props: Props) => {
  const { title, description } = props

  return (
    <Wrapper>
      <SectionTitle title={title} />
      <Description>{description}</Description>
    </Wrapper>
  )
}

export default SmallDetailsCard

const Wrapper = styled.div``

const Description = styled.p`
  max-width: 730px;
  line-height: 50px;

  letter-spacing: 0.1em;
`
