import React from "react"
import styled from "styled-components"
import { valuesData, valuesDataFr } from "../../data/aboutPagedata"
import useLanguage from "../../states/context/websiteContext"
import { Language } from "../../states/context/websiteReducer"
import SectionTitle from "../titles/sectiontitle"

const ValuesCard = () => {
  const { language } = useLanguage()
  const isEnglish = language == Language.en
  return (
    <Wrapper>
      <SectionTitle title={isEnglish ? "Values" : "Valeurs"} />
      <ValuesWrapper>
        {(isEnglish ? valuesData : valuesDataFr).map((data, index) => {
          return (
            <div key={index}>
              <Description>
                <span>{data.title}</span>: {data.description}
              </Description>
            </div>
          )
        })}
      </ValuesWrapper>
    </Wrapper>
  )
}

export default ValuesCard

const Wrapper = styled.div``

const ValuesWrapper = styled.div`
  display: grid;
  gap: 20px;
`

const Description = styled.p`
  max-width: 730px;
  line-height: 50px;

  letter-spacing: 0.1em;

  span {
    font-weight: bold;
  }
`
