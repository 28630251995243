import React, { useRef } from "react"
import styled from "styled-components"

import useOnScreen from "../../utils/hooks/useOnScreen"
import { missionData, missionDataFr, visionData, visionDataFr } from "../../data/aboutPagedata"
import SmallDetailsCard from "../cards/smallDetailsCard"
import ValuesCard from "../cards/valuesCard"
import useLanguage from "../../states/context/websiteContext"
import { Language } from "../../states/context/websiteReducer"

const MissionVisionValuesSection = () => {
  const { language } = useLanguage()
  const isEnglish = language == Language.en
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);

  return (
    <Wrapper ref={ref} className={isVisible ? "animate__animated animate__fadeIn animate__delay-0.5s" : undefined}>
      <LeftWrapper>
        {(isEnglish ? missionData : missionDataFr).map((data, index) => (
          <SmallDetailsCard
            key={index}
            title={data.title}
            description={data.description}
          />
        ))}
        <Image src="/images/missionImage.png" />
        {(isEnglish ? visionData : visionDataFr).map((data, index) => (
          <SmallDetailsCard
            key={index}
            title={data.title}
            description={data.description}
          />
        ))}
      </LeftWrapper>
      <ValuesCard />
    </Wrapper>
  )
}

export default MissionVisionValuesSection

const Wrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 100px 0;

  @media (max-width: 1220px) {
    grid-template-columns: 1fr;

  }
`

const LeftWrapper = styled.div`
  display: grid;
  gap: 50px;
`

const Image = styled.img`
  width: 500px;

  @media (max-width: 600px) {
    width: 350px;
  }

  @media (max-width: 400px) {
    width: 250px;
  }
`
