import React from "react"
import styled from "styled-components"

interface Props {
  text: String
  onClick?: () => any
  type?: "button" | "submit" | "reset"
}

const BlueButton = ({ text, onClick, type = "text" }: Props) => {
  return (
    <Wrapper onClick={onClick} type={type}>
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default BlueButton

const Wrapper = styled.button`
  background: #005085;
  border: none;
  padding: 10px 40px;
  height: fit-content;
  cursor: pointer;
`

const Text = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 50px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
`
