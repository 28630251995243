import React from "react"
import styled from "styled-components"

import useLanguage from "../../states/context/websiteContext"
import { ActivePage, Language } from "../../states/context/websiteReducer"
import BlueButton from "../buttons/blueButton"

interface Props {
  description: string
  image: string
  imageFirst?: boolean
  includesButton?: boolean
}

interface StyledProps {
  includesButton: boolean
}

const DetailsCard = (props: Props) => {
  const { description, image, imageFirst = false, includesButton } = props
  const { setActivePage, language } = useLanguage()
  const isEnglish = language == Language.en

  return (
    <Wrapper>
      {imageFirst && <Image src={image} />}
      <div>
        <Description includesButton={includesButton}>{description}</Description>
        {includesButton && (
          <BlueButton
            text={isEnglish ? "Learn more" : "En apprendre plus"}
            onClick={() => {
              setActivePage(ActivePage.services)
              location.href = "/services"
            }}
          />
        )}
      </div>
      {!imageFirst && <Image src={image} />}
    </Wrapper>
  )
}

export default DetailsCard

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 40px;
  align-items: center;

  @media (max-width: 1220px) {
    grid-template-columns: auto;
  }
`

const Description = styled.p<StyledProps>`
  max-width: 730px;
  line-height: 40px;

  letter-spacing: 0.1em;
  margin-bottom: ${props => (props.includesButton ? "20px" : "0px")};
`

const Image = styled.img`
  height: 500px;

  @media (max-width: 550px) {
    height: 400px;
  }

  @media (max-width: 360px) {
    height: 300px;
  }
`
