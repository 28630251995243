import React, { useRef } from "react"
import styled from "styled-components"

import useWebiste from "../states/context/websiteContext"
import useOnScreen from "../utils/hooks/useOnScreen"
import { aboutData, aboutDataFr, whatWeDoData, whatWeDoDataFr } from "../data/aboutPagedata"

import SEO from "../components/seo"
import DetailsCard from "../components/cards/detailsCard"
import Layout from "../components/layout"
import SectionTitle from "../components/titles/sectiontitle"
import MissionVisionValuesSection from "../components/sections/missionVisionValuesSection"
import { Language } from "../states/context/websiteReducer"

const AboutPage = () => {
  const { language } = useWebiste()
  const isEnglish = language == Language.en
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const isVisible2 = useOnScreen(ref2);

  return (
    <Layout>
      <SEO lang={language} title={isEnglish ? "About" : "À propos"} />
      <Wrapper >
        <div ref={ref} className={isVisible ? "animate__animated animate__fadeIn animate__delay-0.5s" : undefined}>
        {(isEnglish ? aboutData : aboutDataFr).map((data, index) => {
          return (
            <CardWrapper key={index} >
              <SectionTitle title={isEnglish ? "Who are we?" : "Qui sommes-nous ?"} />
              <DetailsCard description={data.description} image={data.image} />
            </CardWrapper>
          )
        })}
        </div>
        <MissionVisionValuesSection />
        <div ref={ref2} className={isVisible2 ? "animate__animated animate__fadeIn animate__delay-0.5s" : undefined}>
        {(isEnglish ? whatWeDoData : whatWeDoDataFr).map((data, index) => {
          return (
            <CardWrapper key={index}>
              <SectionTitle title={isEnglish ? "What do we do?" : "Que faisons-nous ?"} />
              <DetailsCard
                description={data.description}
                image={data.image}
                imageFirst={true}
                includesButton={true}
                />
            </CardWrapper>
          )
        })}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default AboutPage

const Wrapper = styled.div`
  padding: 100px 0;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 1220px) {
    padding: 40px;
  }
`

const CardWrapper = styled.div``
