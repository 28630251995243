// ABOUT
export const aboutData = [
  {
    description: "We are Le Clan Jetté Forage Inc., a horizontal direction drilling company based in Quebec, but doing business in Ontario and Quebec. We specialize in performing high-quality drilling using the latest available technology. Directional drilling is the technology that allows us to create an excavation under the ground to pass in pipes. All this work is done with minimal environmental damage. Navigate through our website to learn more about our services!",
    image: "/images/directionalDrillingimage.png",
  }
]

export const aboutDataFr = [
  {
    description: "Nous sommes Le Clan Jetté Forage Inc, une entreprise de forage directionnel basée au Québec, mais faisant affaire en Ontario et au Québec. Nous nous spécialisons dans la réalisation de forages de haute qualité en utilisant les dernières technologies disponibles. Le forage directionnel est la technologie qui nous permet de créer une excavation sous le sol pour y faire passer des tuyaux. Tout ce travail est effectué avec un minimum de dommages environnementaux. Naviguez sur notre site Web pour en savoir plus sur nos services !",
    image: "/images/directionalDrillingimage.png",
  }
]

// MISSION
export const missionData = [
  {
    title: "Mission",
    description: "To perform quality-based drilling using the latest technology available, while respecting the environment and ensure the health and security of our workers."
  }
]

export const missionDataFr = [
  {
    title: "Mission",
    description: "Réaliser des forages de qualité en utilisant les dernières technologies disponibles, tout en respectant l'environnement et en assurant la santé et la sécurité de nos travailleurs."
  }
]

// VISION
export const visionData = [
  {
    title: "Vision",
    description: "To become the leader in directional drilling in Canada, and to make this technology the norm in the construction and telecommunication fields."
  }
]

export const visionDataFr = [
  {
    title: "Vision",
    description: "Devenir le chef de file du forage directionnel au Canada, et faire de cette technologie la norme dans les domaines de la construction et des télécommunications."
  }
]

// VALUES
export const valuesData = [
  {
    title: "Customer satisfaction",
    description: "Always provide an impeccable service to customers and respond as best to meet their needs.",
  },
  {
    title: "Health and security at work",
    description: "Ensure the health and security of all our workers, targeting 'zero incident'.",
  },
  {
    title: "Communication",
    description: "Communicate as quickly as possible all useful information throughout the entire company and to all concerned partners.",
  },
  {
    title: "Protect the environment",
    description: "Execute the works while minimizing as much as possible negative environmental impacts.",
  },
  {
    title: "Team spirit",
    description: "Build strong and close bonds between team members, so that everyone feels at home.",
  }
]

export const valuesDataFr = [
  {
    title: "Satisfaction des clients",
    description: "Toujours fournir un service impeccable aux clients et répondre au mieux à leurs besoins.",
  },
  {
    title: "Santé et sécurité au travail",
    description: "Assurer la santé et la sécurité de tous nos travailleurs, en visant le 'zéro incident'.",
  },
  {
    title: "Communication",
    description: "Communiquer le plus rapidement possible toute information utile à l'ensemble de l'entreprise et à tous les partenaires concernés.",
  },
  {
    title: "Protéger l'environnement",
    description: "Exécuter les travaux en minimisant autant que possible les impacts négatifs sur l'environnement.",
  },
  {
    title: "Esprit d'équipe",
    description: "Créez des liens forts et étroits entre les membres de l'équipe, afin que chacun fasse partie de la famille.",
  }
]

// WHAT WE DO
export const whatWeDoData = [
  {
    description: "Without performing an excavation, we can, with great precision, perform a direction drilling by control the drilling curve, avoiding potential obstacles. For a successful installation of a pipe without excavation, expertise is essential. With this technology, our mission is to protect the geography that surrounds us, all the while minimizing negative environmental impacts. In short, directional drilling is the solution of the future!",
    image: "/images/whatWeDoImage.png",
  }
]

export const whatWeDoDataFr = [
  {
    description: "Sans réaliser d'excavation, on peut, avec une grande précision, réaliser un forage dirigé en contrôlant la courbe de forage, en évitant les obstacles potentiels. Pour réussir l'installation d'une canalisation sans excavation, l'expertise est essentielle. Avec cette technologie, notre mission est de protéger la géographie qui nous entoure, tout en minimisant les impacts environnementaux négatifs. En bref, le forage directionnel est la solution de l'avenir !",
    image: "/images/whatWeDoImage.png",
  }
]