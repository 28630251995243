import React from "react"
import styled from "styled-components"

interface Props {
  title: String
}

const SectionTitle = ({ title }: Props) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Underline />
    </Wrapper>
  )
}

export default SectionTitle

const Wrapper = styled.div`
  margin-bottom: 50px;
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;

  letter-spacing: 0.1em;
  text-transform: uppercase;
`

const Underline = styled.div`
  width: 65px;
  height: 10px;

  background: #005085;
`
